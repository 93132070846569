import React from "react";

type ToolbarProps = {
  children?: any;
  title?: string;
  subTitle?: React.ReactNode | string;
};

const Toolbar: React.FC<ToolbarProps> = ({ children, title, subTitle }) => {
  return (
    <div className="toolbar">
      <div className="container-fluid d-flex flex-stack">
        <div className="container-fluid d-flex flex-stack">
          <div className="d-flex align-items-center me-3 flex-wrap lh-1">
            <h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">
              {title}
            </h1>
            {
              subTitle && <span className="w-100 fs-5 my-1">{subTitle}</span>
            }
          </div>
          <div className="d-flex align-items-center py-1">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Toolbar;

import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomTable from "../../core/CustomTable";
import { IFilterCheckedSupplier } from "../../../models";
import { PaginationProps, Select } from "antd";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  completeApplication,
  getAllCheckedSuppliers,
} from "../../../redux/services/supplierService";
import Input from "../../core/Input";
import { ColumnGroupType, ColumnType } from "antd/lib/table";
import { CheckedSupplierDto } from "../../../services/openapi";
import { SVG } from "../../core/SVG";
import {DeleteIcon, DoneIcon, EyeIcon, SearchTableIcon} from "../../Icons";
import instance from "../../../axios";
import { toast } from "react-toastify";
import pdf from "../../../assets/icons/pdf.svg";
// import Supplier from "../CheckSupplier/Supplier";
import CreateUpdateSupplier from "./CreateUpdateSupplier";
import ConfirmModal from "../../core/ConfirmModal";
import { fieldCheck } from "../../../helpers/fieldCheck";
import {Link} from "react-router-dom";

const CheckedSuppliersPage = () => {
  const { t } = useTranslation();

  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(25);
  const [filter, setFilter] = useState<IFilterCheckedSupplier>({
    checkedUser: "",
    inn: "",
    organizationId: undefined,
    organizationName: "",
    status: undefined,
  });
  const onChangePagination: PaginationProps["onChange"] = (pageNumber) => {
    setPage(pageNumber - 1);
  };
  const onShowSizeChange: PaginationProps["onShowSizeChange"] = (
    current,
    pageSize
  ) => {
    setPage(current);
    setSize(pageSize);
  };

  const dispatch = useAppDispatch();
  const { suppliers, loading, error, totalCount } = useAppSelector(
    (state) => state.supplier
  );
  const { userMe } = useAppSelector((state) => state.user);
  useEffect(() => {
    dispatch(getAllCheckedSuppliers({ page, size }));
  }, [dispatch, page, size]);

  const handleChangeFilter = (event: ChangeEvent<HTMLInputElement>) => {
    let { name, value } = event.target;
    setFilter((filter) => ({ ...filter, [name]: value }));
  };
  const handleSelectChangeFilter = (name: string, value: string) => {
    setFilter((filter) => ({ ...filter, [name]: value }));
  };

  const handleFilter = async () => {
    await dispatch(
      getAllCheckedSuppliers({
        page: 0,
        size,
        ...fieldCheck(filter),
      })
    );
  };

  const handleClearFilter = async () => {
    setFilter({
      checkedUser: "",
      inn: "",
      organizationName: "",
      organizationId: undefined,
      status: undefined,
    });
    await dispatch(
      getAllCheckedSuppliers({
        page: 0,
        size,
      })
    );
  };

  const downloadReport = async (link?: string, filename?: string) => {
    try {
      let res = await instance.get(`/api/v1/file/resource/reports/${link}`, {
        responseType: "blob",
      });
      if (res.data) {
        let blob = new Blob([res.data], { type: "application/pdf" });
        let url = URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.target = "_blank";
        a.href = url;
        let currentDate = new Date();
        let date =
          (currentDate.getDate() < 10
            ? `0${currentDate.getDate()}`
            : currentDate.getDate()) +
          "-" +
          (currentDate.getMonth() + 1 < 10
            ? `0${currentDate.getMonth() + 1}`
            : currentDate.getMonth() + 1) +
          "-" +
          currentDate.getFullYear();
        let replaceName = filename?.replace(/"/g, " ").trim()

          a.download = `${replaceName?.slice(0,3)}${replaceName && replaceName?.slice(0, 3).trim()?.length > 2 ? ` ''` : `''`}${replaceName?.slice(5)}${`''`} ${date}`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
    } catch (err: any) {
      toast(err.message, {
        type: "error",
      });
    }
  };

  const handleComplete = async (id: number) => {
    try {
      let res: any = await dispatch(completeApplication(id));
      if (res.payload) {
        if (res.payload.success) {
          toast(res.payload?.message, {
            type: "success",
          });
          dispatch(getAllCheckedSuppliers({ page, size }));
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const statuses = [
    {
      label: t("new"),
      value: "NEW",
    },
    {
      label: t("inProcess"),
      value: "IN_PROCESS",
    },
    {
      label: t("completed"),
      value: "COMPLETED",
    },
  ];

  const columns: (
    | ColumnGroupType<CheckedSupplierDto>
    | ColumnType<CheckedSupplierDto>
  )[] = [
    {
      title: "№",
      key: "id",
      width: "80px",
      className: "text-center gx-0",
      render: (row) => {
        let index = suppliers.indexOf(row);
        return page * size + index + 1;
      },
    },
    {
      title: (
        <div className="d-flex list-group">
          <div className="mt-4 mb-2 text-start">{t("organization")} </div>
          <div className="fv-row mb-4 w-150px min-w-100">
            <Input
              type="text"
              name="checkedOrganization"
              value=""
              disabled
              onChange={handleChangeFilter}
            />
          </div>
        </div>
      ),
      key: "checkedOrganization",
      render: (row: CheckedSupplierDto) => {
        return <div>{row.checkedOrganization?.name}</div>;
      },
    },
    {
      title: (
        <div className="d-flex list-group">
          <div className="mt-4 mb-2 text-start"> {t("tin")}</div>
          <div className="fv-row mb-4 w-150px min-w-100">
            <Input
              type="text"
              name="inn"
              value={filter.inn}
              onChange={handleChangeFilter}
            />
          </div>
        </div>
      ),
      dataIndex: "inn",
      key: "inn",
    },
    {
      title: (
        <div className="d-flex list-group">
          <div className="mt-4 mb-2 text-start">{t("checkedSuppliers")} </div>
          <div className="fv-row mb-4 w-2000px min-w-175px">
            <Input
              type="text"
              name="organizationName"
              value={filter.organizationName}
              onChange={handleChangeFilter}
            />
          </div>
        </div>
      ),
      dataIndex: "organizationName",
      key: "organizationName",
    },
    {
      title: (
        <div className="d-flex list-group">
          <div className="mt-4 mb-2 text-start"> {t("status")} </div>
          <div className="fv-row mb-4 w-175px min-w-100">
            <Select
              placeholder={t("choose")}
              className="w-100 form-control form-select-sm p-0"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              allowClear
              value={filter.status}
              onChange={(e) => handleSelectChangeFilter("status", e)}
              options={statuses.map((el) => {
                return {
                  value: el.value,
                  label: el.label,
                };
              })}
            />
          </div>
        </div>
      ),
      dataIndex: "status",
      key: "status",
    },
    {
      title: (
        <div className="d-flex list-group">
          <div className="mt-4 mb-2 text-start"> {t("user")} </div>
          <div className="fv-row mb-4 w-150px min-w-100">
            <Input
              type="text"
              name="checkedUser"
              value={filter.checkedUser}
              onChange={handleChangeFilter}
            />
          </div>
        </div>
      ),
      key: "checkedUser",
      render: (row: CheckedSupplierDto) => {
        return <div>{row.checkedUser?.shortName}</div>;
      },
    },
    {
      title: (
        <div className="d-flex list-group">
          <div className="mt-4 mb-2 text-start"> {t("time")} </div>
          <div className="fv-row mb-4 w-150px min-w-100">
            <Input
              type="text"
              name="time"
              value=""
              disabled
              onChange={handleChangeFilter}
            />
          </div>
        </div>
      ),
      dataIndex: "time",
      key: "time",
    },
    {
      title: (
        <div className="d-flex list-group">
          <div className="mt-4 mb-2"> {t("report")}</div>
          <div className="fv-row mb-4">
            <Input
              type="text"
              name="report"
              value=""
              disabled
              onChange={handleChangeFilter}
            />
          </div>
        </div>
      ),
      key: "report",
      fixed: "right",
      width: 100,
      className: "text-center",
      render: (row: CheckedSupplierDto) => {
        return row.statusCode === "COMPLETED" ? (
          <div className="d-flex justify-content-center flex-shrink-0">
            <div
              onClick={async () => {
                await downloadReport(row.reportFile?.url, row?.organizationName);
              }}
              className="btn btn-icon-primary btn-light-primary mb-0 ms-2 p-2"
            >
              <img src={pdf} width="30px" height="30px" alt="pdf" />
            </div>
          </div>
        ) : (
          ""
        );
      },
    },
    {
      title: (
        <div className="d-flex list-group">
          <div className="mt-4 mb-2"> {t("action")}</div>
          <div className="fv-row mb-4 d-flex justify-content-center ">
            <div
              onClick={handleClearFilter}
              className="btn btn-icon btn-bg-light btn-active-color-primary h-30px  mb-0 me-0"
            >
              <SVG icon={<DeleteIcon />} className="svg-icon-1" />
            </div>
            <div
              onClick={handleFilter}
              className="btn btn-icon btn-bg-light btn-active-color-primary h-30px  mb-0 me-0"
            >
              <SVG icon={<SearchTableIcon />} className="svg-icon-1" />
            </div>
          </div>
        </div>
      ),
      key: "action",
      fixed: "right",
      width: 120,
      className: "text-center",
      render: (row: CheckedSupplierDto) => {
        return (
          <div className="d-flex justify-content-center">
            {row.statusCode === "COMPLETED" ||
            row.statusCode === null ||
            userMe.role === "ADMIN" ||
            userMe.role === "SYS_ADMIN" ? (
              <>
                {/*<Supplier id={row.id!} />*/}
                <Link to={`/checked-suppliers/${row.id!}`}>
                  <button
                      className={
                        "btn btn-icon btn-bg-light btn-active-color-primary me-1 w-20px h-20px"
                      }
                  >
                    <SVG icon={<EyeIcon/>} className="svg-icon-2"/>
                  </button>
                </Link>
              </>
            ) : (
              ""
            )}
            {row.statusCode !== "COMPLETED"
              ? userMe.permissions!.map(
                  (el) =>
                    el.code === "UPDATE_COMPLETE_APPLICATION" && (
                      <CreateUpdateSupplier id={row.id} />
                    )
                )
              : ""}
            {row.statusCode === "COMPLETED"
              ? ""
              : userMe.permissions!.map(
                  (el) =>
                    el.code === "UPDATE_COMPLETE_APPLICATION" && (
                      <ConfirmModal
                        key={el.id}
                        btnClass="btn-icon btn-bg-light btn-active-color-primary w-20px h-20px me-1"
                        icon={
                          <SVG icon={<DoneIcon />} className="svg-icon-2" />
                        }
                        title={t("complete")}
                        handleSubmit={() => handleComplete(row.id!)}
                      />
                    )
                )}
          </div>
        );
      },
    },
  ];

  const data = useMemo(() => suppliers, [suppliers]);

  return (
    <CustomTable
      columns={columns}
      error={error}
      data={data}
      loading={loading}
      page={page}
      pageSize={size}
      paginationShowChange={onShowSizeChange}
      paginationChange={onChangePagination}
      totalCount={totalCount}
    />
  );
};

export default CheckedSuppliersPage;

import React from 'react';
import Toolbar from "../../Toolbar";
import CardBody from "../../core/CardBody";
import CheckedSuppliersPage from "./index";
import {useTranslation} from "react-i18next";

const CheckedSuppliersAdmin = () => {

  const {t} = useTranslation()

  return (
    <>
      <Toolbar
        title={t("checkedSuppliers")}
      />
      <CardBody
        children={<CheckedSuppliersPage />}
      />
    </>
  );
};

export default CheckedSuppliersAdmin;

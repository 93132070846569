import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import Toolbar from "../../Toolbar";
import pdf from "../../../assets/icons/pdf.svg";
import instance from "../../../axios";
import {toast} from "react-toastify";
import {Spin, Tabs} from "antd";
import CardBody from "../../core/CardBody";
import {useTranslation} from "react-i18next";
import {getOneCheckedSuppliers} from "../../../redux/services/supplierService";
import {formatMoney} from "../../../helpers/formatMoney";
import {capitalizeFirstLetter, hasNumbers} from "../../../helpers";
import {getCurrencyType} from "../../../redux/services/dictionaryService";
import {Chart as ChartJS, ArcElement, Tooltip, Legend, ChartOptions} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import {
    CheckedSupplierControllerService,
    CheckedSupplierMibCreditorDocumentDTO,
    CheckedSupplierMibDebtorDocumentDTO, CheckedSupplierMinjustDTO, CheckedSupplierSudMonitoringDto
} from "../../../services/openapi";
import OrganizationInfo from "./OrganizationInfo";
import {SVG} from "../../core/SVG";
import {DownloadIcon} from "../../Icons";
import CourtMonitoring from "./CourtMonitoring";

ChartJS.register(ArcElement, Tooltip, Legend);


const SupplierInfo = () => {

    const {t} = useTranslation();
    const {id}: {id?: number} = useParams()
    const [loadingFile, setLoadingFile] = useState(false);
    const [description, setDescription] = useState<string>("");

    const {supplier, mibFiles, sudFiles, minjustFiles, mintransFiles} = useAppSelector(state => state.supplier)
    const {currency} = useAppSelector((state) => state.dictionary);
    const {userMe} = useAppSelector((state) => state.user);
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(getCurrencyType())
        dispatch(getOneCheckedSuppliers(Number(id)));
    }, [dispatch, id])

    console.log('supplier', supplier)

    const downloadReport = async (link?: string, filename?: string) => {
        setLoadingFile(true);
        try {
            let res = await instance.get(`/api/v1/file/resource/reports/${link}`, {
                responseType: "blob",
            });
            if (res.data) {
                let blob = new Blob([res.data], { type: "application/pdf" });
                let url = URL.createObjectURL(blob);
                let a = document.createElement("a");
                a.target = "_blank";
                a.href = url;
                let currentDate = new Date();
                let date =
                    (currentDate.getDate() < 10
                        ? `0${currentDate.getDate()}`
                        : currentDate.getDate()) +
                    "-" +
                    (currentDate.getMonth() + 1 < 10
                        ? `0${currentDate.getMonth() + 1}`
                        : currentDate.getMonth() + 1) +
                    "-" +
                    currentDate.getFullYear();
                let replaceName = filename?.replace(/"/g, " ").trim()

                a.download = `${replaceName?.slice(0,3)}${replaceName && replaceName?.slice(0, 3).trim()?.length > 2 ? ` ''` : `''`}${replaceName?.slice(5)}${`''`} ${date}`;
                document.body.appendChild(a);
                a.click();
                a.remove();
            }
        } catch (err: any) {
            toast(err.message, {
                type: "error",
            });
        } finally {
            setLoadingFile(false);
        }
    };


    const getSoftRandomColor = () => {
        const hue = Math.floor(Math.random() * 360); // Random hue from full spectrum
        const saturation = Math.floor(Math.random() * 50) + 80; // Keep saturation soft (40-70%)
        const lightness = Math.floor(Math.random() * 5) + 60; // Keep light colors (70-90%)
        return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    };
    const labels = supplier?.founders?.map(el => el?.name);
    const colors = labels?.map((_) => getSoftRandomColor());
    const data = {
        labels,
        datasets: [
            {
                label: '',
                data: supplier?.founders?.map(el => el?.percent),
                backgroundColor: colors,
                borderWidth: 1,
            },
        ],
    };

    const options: ChartOptions<'doughnut'> = {
        responsive: true,
        maintainAspectRatio: false,
        cutout:'80%',
        plugins: {
            legend: { display: false }
        },
    };

    const CustomLegend = ({ legendLabels, colors }: { legendLabels: (string | undefined)[] | undefined; colors: string[] | undefined }) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', justifyContent: 'center' }}>
                {legendLabels?.map((label, index) => (
                    <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <div
                            style={{
                                width: '16px',
                                height: '16px',
                                backgroundColor: colors && colors[index],
                                borderRadius: '50%',
                            }}
                        />
                        <span>{label}</span>
                    </div>
                ))}
            </div>
        );
    };


    const ratingText = (text: any) => {
        return text.toUpperCase();
    };

    const ratingValue = (text: any) => {
        let result = text;
        if (result.toLowerCase() === "a") {
            return "Высокий уровень - 3";
        }
        if (result.toLowerCase() === "aa") {
            return "Очень высокий уровень - 2";
        }
        if (result.toLowerCase() === "aaa") {
            return "Наивысший уровень - 1";
        }

        if (result.toLowerCase() === "b") {
            return "Умеренно высокий уровень - 3";
        }
        if (result.toLowerCase() === "bb") {
            return "Умеренно высокий уровень - 2";
        }
        if (result.toLowerCase() === "bbb") {
            return "Умеренно высокий уровень - 1";
        }

        if (result.toLowerCase() === "c") {
            return "Удовлетворительный уровень - 3";
        }
        if (result.toLowerCase() === "cc") {
            return "Удовлетворительный уровень - 2";
        }
        if (result.toLowerCase() === "ccc") {
            return "Удовлетворительный уровень - 1";
        }

        if (result.toLowerCase() === "d") {
            return "Низкий уровень - 3";
        }
        if (result.toLowerCase() === "dd") {
            return "Низкий уровень - 2";
        }
        if (result.toLowerCase() === "ddd") {
            return "Низкий уровень - 1";
        }
        return result;
    };


    const downloadFile = async (url?: string, name?: string) => {
        try {
            let res = await instance.get(`/api/v1/file${url}`, {
                responseType: "blob",
            });
            if (res.data) {
                let blob = new Blob([res.data], {type: "application/pdf"});
                let url = URL.createObjectURL(blob);
                let a = document.createElement("a");
                a.target = "_blank";
                a.href = url;
                a.download = `${name}`;
                document.body.appendChild(a);
                a.click();
                a.remove();
            }
        } catch (err: any) {
            toast(err.message, {
                type: "error",
            });
        }
    };

    const addDescription = async () => {
        try {
            let res = await CheckedSupplierControllerService.appDescUpdateUsingPost({
                appId: id,
                description: description,
            });
            toast(res.message, {type: "success"});
            await dispatch(getOneCheckedSuppliers(Number(id)));
            setDescription("");
        } catch (err: any) {
            toast(err?.body?.errors[0]?.errorMsg || err.message, {
                type: "error",
            });
        }
    };

    return (
        <>
            <Toolbar
                title={supplier?.organizationName}
                subTitle={<>
                    Статус компании: <span className={[supplier?.activityStatus?.toLowerCase().includes('бездей') ? 'text-red' : 'text-success', 'fw-bolder ms-1'].join(' ')}>
                    {supplier?.activityStatus}
                </span>
                </>}
                children={
                    <div className="d-flex justify-content-center align-items-center flex-shrink-0">
                        {loadingFile && <Spin />}
                        <div
                            onClick={async () => {
                                await downloadReport(supplier?.reportFile?.url, supplier?.organizationName);
                            }}
                            className="btn btn-icon-primary btn-light-primary mb-0 ms-2 p-2 px-4"
                        >
                            <img src={pdf} width="30px" height="30px" alt="pdf" />
                            Скачать справку о компании
                        </div>
                        <Link to={"/check"} >
                            <button className="btn btn-icon-danger btn-light-danger ms-2">
                                Назад
                            </button>
                        </Link>
                    </div>
                }
            />
            <CardBody
                children={<div>
                    <Tabs
                        defaultActiveKey="common"
                        tabPosition='left'
                        items={[
                            {

                                label: t("commonInfo"),
                                key: "common",
                                children: <>
                            <div className="card-body p-3">
                                    <div className="row mb-7">
                                        <label className="col-lg-6 fw-bold">
                                            {t("dateGovRegister")}
                                        </label>
                                        <div className="col-lg-6">
                      <span className="fw-bolder fs-6 text-dark">
                        {supplier.registerDate}
                      </span>
                                        </div>
                                    </div>

                                    <div className="row mb-7">
                                        <label className="col-lg-6 fw-bold">
                                            {t("numGovRegister")}
                                        </label>
                                        <div className="col-lg-6">
                      <span className="fw-bolder fs-6 text-dark">
                        {supplier?.registerNumber}
                      </span>
                                        </div>
                                    </div>

                                    <div className="row mb-7">
                                        <label className="col-lg-6 fw-bold">{t("tin")}</label>
                                        <div className="col-lg-6 fv-row">
                                            <span className="fw-bolder fs-6">{supplier?.inn}</span>
                                        </div>
                                    </div>

                                    <div className="row mb-7">
                                        <label className="col-lg-6 fw-bold">{t("orgName")}</label>
                                        <div className="col-lg-6 fv-row">
                      <span className="fw-bolder fs-6">
                        {supplier?.organizationName}
                      </span>
                                        </div>
                                    </div>

                                    <div className="row mb-7">
                                        <label className="col-lg-6 fw-bold">{t("codeOked")}</label>
                                        <div className="col-lg-6">
                      <span className="fw-bolder fs-6 text-dark">
                        {supplier.oked}
                      </span>
                                        </div>
                                    </div>

                                    <div className="row mb-7">
                                        <label className="col-lg-6 fw-bold">
                                            Основной вид деятельности
                                        </label>
                                        <div className="col-lg-6 d-flex align-items-center">
                      <span className="fw-bolder fs-6 me-2">
                        {capitalizeFirstLetter(supplier?.activity)}
                      </span>
                                        </div>
                                    </div>

                                    <div className="row mb-7">
                                        <label className="col-lg-6 fw-bold">
                                            Юридический адрес
                                        </label>
                                        <div className="col-lg-6 d-flex align-items-center">
                      <span className="fw-bolder fs-6 me-2">
                        {capitalizeFirstLetter(supplier?.address)}
                      </span>
                                        </div>
                                    </div>

                                    <div className="row mb-7">
                                        <label className="col-lg-6 fw-bold">
                                            {t("numberOfWorkDay")}
                                        </label>
                                        <div className="col-lg-6 d-flex align-items-center">
                      <span className="fw-bolder fs-6 me-2">
                        {supplier?.numberOfWorkDay}
                      </span>
                                        </div>
                                    </div>

                                    <div className="row mb-7">
                                        <label className="col-lg-6 fw-bold">
                                            Руководитель юридического лица
                                        </label>
                                        <div className="col-lg-6">
                      <span className="fw-bolder fs-6 text-dark">
                        {supplier?.directorName}
                      </span>
                                        </div>
                                    </div>
                                    <div className="row mb-7">
                                        <label className="col-lg-6 fw-bold">Бухгалтер</label>
                                        <div className="col-lg-6">
                      <span className="fw-bolder fs-6 text-dark">
                        {capitalizeFirstLetter(supplier?.accountantName)}
                      </span>
                                        </div>
                                    </div>
                                    <div className="row mb-7">
                                        <label className="col-lg-6 fw-bold">Уставный капитал</label>
                                        <div className="col-lg-6">
                      <span className="fw-bolder fs-6 text-dark">
                        {formatMoney(supplier?.ustavCapital?.match(/\d+(\.\d+)?/g))} {' '}
                          {/*@ts-ignore*/}
                          { currency?.find(el =>
                              el?.code?.toLowerCase() === (supplier?.ustavCapital?.match(/[a-zA-Z]+/g)?.[0] || '')
                          )?.name }
                      </span>
                                        </div>
                                    </div>

                                <div className="row mb-7">
                                    <label className="col-lg-6 fw-bold">Резидент IT-Park</label>
                                    <div className="col-lg-6">
                      <span className="fw-bolder fs-6 text-dark">
                        {supplier.isItParkResident}
                      </span>
                                    </div>
                                </div>

                                </div>
                                    <div className="card">
                                        <div className="card-header cursor-pointer p-3">
                                            <div className="card-title m-0">
                                                <h3 className="fw-bolder m-0">Банковские реквизиты</h3>
                                            </div>
                                        </div>
                                        <div className="card-body p-3">
                                            <div className="row mb-7">
                                                <label className="col-lg-6 fw-bold">Расчетный счет</label>
                                                <div className="col-lg-6">
                      <span className="fw-bolder fs-6 text-dark">
                        {supplier?.account}
                      </span>
                                                </div>
                                            </div>
                                            <div className="row mb-7">
                                                <label className="col-lg-6 fw-bold">МФО банка</label>
                                                <div className="col-lg-6">
                      <span className="fw-bolder fs-6 text-dark">
                        {supplier?.mfo}
                      </span>
                                                </div>
                                            </div>
                                            <div className="row mb-7">
                                                <label className="col-lg-6 fw-bold">
                                                    Наименование банка
                                                </label>
                                                <div className="col-lg-6">
                      <span className="fw-bolder fs-6 text-dark">
                        {supplier?.bank}
                      </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header cursor-pointer p-0">
                                            <div className="card-title m-0">
                                                <h3 className="fw-bolder m-0">Вниманию клиентов</h3>
                                            </div>
                                        </div>
                                        <div className="card-body p-3">
                                            <div className="row mb-7">
                                                <label className="col-lg-12">{supplier?.description}</label>
                                            </div>
                                        </div>
                                    </div>

                                    {userMe?.role === "ADMIN" || userMe?.role === "SYS_ADMIN" ? (
                                        <div className="card">
                                            <div className="card-header cursor-pointer p-0">
                                                <div className="card-title m-0">
                                                    <h3 className="fw-bolder m-0">Важная информация</h3>
                                                </div>
                                            </div>
                                            <div className="card-body p-3">
                                                <div className="row mb-7">
                                                    <div className="col-lg-12">
                                                        <div className="fv-row mt-0">
                                                            <label className="fs-6 fw-semibold form-label">
                                                                <span>{t("info")}</span>
                                                            </label>
                                                            <textarea
                                                                className="form-control"
                                                                value={description}
                                                                onChange={(e) => setDescription(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 d-flex mt-2 justify-content-end">
                                                        <button
                                                            className="btn btn-primary"
                                                            onClick={addDescription}
                                                        >
                                                            {" "}
                                                            {t("save")}{" "}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </>
                            },
                            {
                                label: 'Налоговая информация',
                                key: 'tax',
                                children: <>
                                    <div className="card-body">
                                        <div className="row mb-7">
                                            <label className="col-lg-6 fw-bold">
                                                Налоговая задолженность контрагента
                                            </label>
                                            <div className="col-lg-6">
                      <span className="fw-bolder fs-6 text-dark">
                        {formatMoney(supplier?.taxDebt) || "Нет"}{" "}
                          {hasNumbers(supplier?.taxDebt) ? " сум" : ""}
                      </span>
                                            </div>
                                        </div>

                                        <div className="row mb-7">
                                            <label className="col-lg-6 fw-bold">
                                                Статус налогоплательщика
                                            </label>
                                            <div className="col-lg-6 fv-row">
                      <span className="fw-bolder fs-6">
                        {capitalizeFirstLetter(supplier?.activityStatus)}
                      </span>
                                            </div>
                                        </div>

                                        <div className="row mb-7">
                                            <label className="col-lg-6 fw-bold">
                                                Количество сотрудников
                                            </label>
                                            <div className="col-lg-6">
                      <span className="fw-bolder fs-6 text-dark">
                        {supplier?.numberOfEmployee}
                      </span>
                                            </div>
                                        </div>

                                        <div className="row mb-7">
                                            <label className="col-lg-6 fw-bold">
                                                Крупный налогоплательщик
                                            </label>
                                            <div className="col-lg-6 d-flex align-items-center">
                      <span className="fw-bolder fs-6 me-2">
                        {supplier?.largeTaxPayer ? t("yes") : t("no")}
                      </span>
                                            </div>
                                        </div>

                                        {supplier?.isNdsPayer && (
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="card-header cursor-pointer ps-0">
                                                        <div className="card-title m-0">
                                                            <h5 className="fw-bolder m-0">
                                                                Свидетельство о государственной регистрации в
                                                                качестве плательщика НДС:
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div className="card-body px-0">
                                                        <div className="row mb-7">
                                                            <label className="col-lg-6 fw-bold">
                                                                {t("dateGovRegister")}
                                                            </label>
                                                            <div className="col-lg-6">
                                                                <div className="fw-bolder fs-6 text-dark">
                                                                    {supplier?.ndsRegDate}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-7">
                                                            <label className="col-lg-6 fw-bold">
                                                                Номер налогоплательщика НДС
                                                            </label>
                                                            <div className="col-lg-6">
                                                                <div className="fw-bolder fs-6 text-dark">
                                                                    {supplier.ndsRegNumber}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-7">
                                                            <label className="col-lg-6 fw-bold">
                                                                {t("status")}
                                                            </label>
                                                            <div className="col-lg-6">
                                                                <div className="fw-bolder fs-6 text-dark">
                                                                    {supplier.ndsStatus}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-2">
                                                            <label className="col-lg-6 fw-bold">
                                                                Находится в списке предприятий, злоупотребивших
                                                                правом уменьшения суммы НДС, подлежащей уплате
                                                            </label>
                                                            <div className="col-lg-6 d-flex align-items-center">
                                                                <div className="fw-bolder fs-6 text-dark">
                                                                    {supplier?.obnalStatus ? "Да" : "Нет"}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/*<hr />*/}
                                            </div>
                                        )}
                                        <div className="row mb-7">
                                            <label className="col-lg-6 fw-bold">
                                                {t("budgetOrganization")}
                                            </label>
                                            <div className="col-lg-6">
                                                <div className="fw-bolder fs-6 text-dark">
                                                    {supplier?.isBudget ? t("yes") : t("no")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            },
                            {
                                label: t("founders"),
                                key: "founders",
                                children: <div className="card-body">
                                    {supplier?.founders?.map((el, i) => {
                                        return (
                                            <React.Fragment key={i}>
                                                <div className="row mb-7">
                                                    <label className="col-lg-6 fw-bold">Учредитель</label>
                                                    <div className="col-lg-6">
                            <span className="fw-bolder fs-6 text-dark">
                              {el.name}
                            </span>
                                                    </div>
                                                </div>
                                                <div className="row mb-7">
                                                    <label className="col-lg-6 fw-bold">{t("residualBalance")}</label>
                                                    <div className="col-lg-6">
                            <span className="fw-bolder fs-6 text-dark">
                              {/*{el?.inn && el?.inn?.length > 9 ? muskNumber(el.inn) : el.inn}*/}
                                {el.percent !== undefined && supplier.ustavCapital
                                    ? formatMoney((Number(el.percent) * Number(supplier?.ustavCapital?.split(' ')[0])) / 100)
                                    : 0}
                            </span>
                                                    </div>
                                                </div>
                                                <div className="row mb-7">
                                                    <label className="col-lg-6 fw-bold">
                                                        Доля в уставном капитале %
                                                    </label>
                                                    <div className="col-lg-6">
                            <span className="fw-bolder fs-6 text-dark">
                              {el.percent}
                            </span>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        );
                                    })}
                                    <div className="d-flex flex-wrap justify-content-between w-100 gap-0 mt-2">
                                        <CustomLegend legendLabels={labels} colors={colors} />
                                        <div style={{ width: '400px', height: '400px' }}>
                                            <Doughnut data={data} options={options} />
                                        </div>
                                    </div>
                                </div>
                            },
                            {
                                label: 'Взаимосвязанные лица',
                                key: 'application',
                                children: <>
                                    <div className="card-body">
                                        {userMe?.organization?.tariffType === "PLATINUM" ? (
                                            supplier?.affiliations ? (
                                                supplier?.affiliations.map((el, i) => {
                                                    return el.tin!.length && el.organizationName!.length ? (
                                                        <React.Fragment key={i}>
                                                            <div className="row mb-7">
                                                                <label className="col-lg-6 fw-bold">
                                                                    {t("tin")}
                                                                </label>
                                                                <div className="col-lg-6">
                                <span className="fw-bolder fs-6 text-dark">
                                  {el?.tin}
                                </span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-7">
                                                                <label className="col-lg-6 fw-bold">
                                                                    {t("orgName")}
                                                                </label>
                                                                <div className="col-lg-6">
                                <span className="fw-bolder fs-6 text-dark">
                                  {el?.organizationName}
                                </span>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    ) : (
                                                        <div className="row mb-7" key={i}>
                                                            <label className="col-lg-6 fw-bold">Сведения</label>
                                                            <div className="col-lg-6">
                              <span className="fw-bolder fs-6 text-dark">
                                Отсутствуют
                              </span>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            ) : (
                                                ""
                                            )
                                        ) : (
                                            <div className="badge badge-light-danger fs-6">
                                                В ТАРИФЕ PLATINUM
                                            </div>
                                        )}
                                    </div>
                                </>
                            },
                            {
                              label: 'Исполнительные дела в БПИ',
                              key: 'mib',
                              children: <>
                                  <div className="card-body">
                                      <div className="card-title m-0">
                                          <h3 className="fw-bolder m-0">
                                              Сумма задолженности контрагента
                                          </h3>
                                      </div>
                                      <div className="row mb-7">
                                          <label className="col-lg-6 fw-bold">
                                              Сведения
                                          </label>
                                          <div className="col-lg-6">
                          <span className="fw-bolder fs-6 text-dark">
                            {supplier?.isOrganizationMibDebtor ? "Да" : "Нет"}
                          </span>
                                          </div>
                                      </div>
                                      <div className="row mb-7">
                                          <label className="col-lg-6 fw-bold">Долг</label>
                                          <div className="col-lg-6">
                      <span className="fw-bolder fs-6 text-dark">
                        {supplier?.organizationMibDebt === null
                            ? ""
                            : supplier?.organizationMibDebt === "0"
                                ? t("no")
                                : formatMoney(supplier?.organizationMibDebt) + " сум"}
                      </span>
                                          </div>
                                      </div>
                                      <div className="row mb-7">
                                          <label className="col-lg-6 fw-bold">
                                              {t("info")}
                                          </label>
                                          <div className="col-lg-6">
                                              {supplier.isOrganizationMibDebtor ? (
                                                  <OrganizationInfo
                                                      title="БПИ / должники"
                                                      data={
                                                          supplier?.organizationMibDebtorDocuments as CheckedSupplierMibDebtorDocumentDTO[]
                                                      }
                                                      type="debtor"
                                                  />
                                              ) : (
                                                  <span className="fw-bolder fs-6 text-dark">
                            Отсутствуют
                           </span>
                                              )}
                                          </div>
                                          <div
                                              className={[
                                                  "row",
                                                  mibFiles?.length > 0 ? " " : "mb-7",
                                              ].join(" ")}
                                          >
                                              <label className="col-lg-6 fw-bold">{t("file")}</label>
                                              {userMe?.organization?.tariffType !== "PLATINUM" ? (
                                                  <div className="col-lg-6">
                                                      <div className="badge badge-light-danger fs-6">
                                                          В ТАРИФЕ PLATINUM
                                                      </div>
                                                  </div>
                                              ) : (
                                                  <div className="col-12 mt-2 row row-cols-1 row-cols-sm-4 rol-cols-md-2 row-cols-lg-4 mx-0">
                                                      {mibFiles?.map((file, i) => {
                                                          return (
                                                              <a
                                                                  onClick={() =>
                                                                      downloadFile(file?.url, file?.name)
                                                                  }
                                                                  className="col py-3 ps-0 mb-3"
                                                                  key={i}
                                                                  href="#/"
                                                              >
                                                                  <div
                                                                      className="btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start py-6">
                                                                      <div className="text-truncate">
                                                                          {file?.name}
                                                                      </div>
                                                                      <button
                                                                          className="btn btn-icon btn-bg-light btn-active-color-primary w-20px h-20px ms-2">
                                                                          <SVG
                                                                              icon={<DownloadIcon/>}
                                                                              className="svg-icon-2"
                                                                          />
                                                                      </button>
                                                                  </div>
                                                              </a>
                                                          );
                                                      })}
                                                  </div>
                                              )}
                                          </div>
                                      </div>
                                      <div className="card-title m-0">
                                          <h3 className="fw-bolder m-0">
                                              Сумма задолженности контрагенту
                                          </h3>
                                      </div>
                                      <div className="row mb-7">
                                          <label className="col-lg-6 fw-bold">
                                              Сведения
                                          </label>
                                          <div className="col-lg-6">
                          <span className="fw-bolder fs-6 text-dark">
                            {supplier?.isOrganizationMibCreditor ? "Да" : "Нет"}
                          </span>
                                          </div>
                                      </div>
                                      <div className="row mb-7">
                                          <label className="col-lg-6 fw-bold">
                                              Долг
                                          </label>
                                          <div className="col-lg-6">
                          <span className="fw-bolder fs-6 text-dark">
                            {supplier?.organizationMibCreditorAmount === null
                                ? ""
                                : supplier?.organizationMibCreditorAmount === "0"
                                    ? t("no")
                                    : formatMoney(supplier?.organizationMibCreditorAmount) + " сум"}

                          </span>
                                          </div>
                                      </div>
                                      <div className="row mb-7">
                                          <label className="col-lg-6 fw-bold">
                                              {t("info")}
                                          </label>
                                          <div className="col-lg-6">
                                              {supplier.isOrganizationMibCreditor ? (
                                                  <OrganizationInfo
                                                      title="БПИ / долг контрагента"
                                                      data={
                                                          supplier?.organizationMibCreditorDocuments as CheckedSupplierMibCreditorDocumentDTO[]
                                                      }
                                                      type="creditor"
                                                  />
                                              ) : (
                                                  <span className="fw-bolder fs-6 text-dark">
                               Отсутствуют
                             </span>
                                              )}
                                          </div>
                                      </div>
                                  </div>
                              </>
                            },
                            {
                                label: 'Судебные дела',
                                key: 'court',
                                children: <>
                                    <div className="card-body">
                                        {userMe?.organization?.tariffType === "PLATINUM" ? (
                                            <>
                                                <div className="row mb-7">
                                                    <label className="col-lg-6 fw-bold">
                                                        Суды по Экономическим делам
                                                    </label>
                                                    <div className="col-lg-6">
                          <span className="fw-bolder fs-6 text-dark">
                            {supplier?.isEconomicSud ? "Да" : "Нет"}
                          </span>
                                                    </div>
                                                </div>
                                                <div
                                                    className={[
                                                        "row d-flex flex-column",
                                                        sudFiles?.length > 0 ? " " : "mb-7",
                                                    ].join(" ")}
                                                >
                                                    <label className="col-12 fw-bold">Решения суда</label>
                                                    <div className="col-12 mt-2 row row-cols-1 row-cols-sm-4 rol-cols-md-2 row-cols-lg-4 mx-0">
                                                        {sudFiles?.map((file, i) => {
                                                            return (
                                                                <a
                                                                    onClick={() =>
                                                                        downloadFile(file?.url, file?.name)
                                                                    }
                                                                    className="col py-3 ps-0 mb-3"
                                                                    key={i}
                                                                    href="#/"
                                                                >
                                                                    <div
                                                                        className="btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start py-6">
                                                                        <div className="text-truncate">
                                                                            {file?.name}
                                                                        </div>
                                                                        <button
                                                                            className="btn btn-icon btn-bg-light btn-active-color-primary w-20px h-20px ms-2">
                                                                            <SVG
                                                                                icon={<DownloadIcon/>}
                                                                                className="svg-icon-2"
                                                                            />
                                                                        </button>
                                                                    </div>
                                                                </a>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                                <div className="row mb-7">
                                                    <label className="col-lg-6 fw-bold">
                                                        {t("courtMonitoring")}
                                                    </label>
                                                    <div className="col-lg-6">
                                                        {supplier.isEconomicSud ? (
                                                            <CourtMonitoring
                                                                data={
                                                                    supplier?.sudMonitorings as CheckedSupplierSudMonitoringDto[]
                                                                }
                                                            />
                                                        ) : (
                                                            <span className="fw-bolder fs-6 text-dark">
                              Отсутствуют
                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <div className="badge badge-light-danger fs-6">
                                                В ТАРИФЕ PLATINUM
                                            </div>
                                        )}
                                    </div>
                                </>
                            },
                            {
                                label: 'Рейтинг',
                                key: 'score',
                                children: <>
                                    <div className="card">
                                        <div className="card-header cursor-pointer p-0">
                                            <div className="card-title m-0">
                                                <h3 className="fw-bolder m-0">
                                                    Электронный рейтинг строительно-подрядных организаций
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="card-body p-0">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="card-header cursor-pointer ps-0">
                                                        <div className="card-title m-0">
                                                            <h5 className="fw-bolder m-0">
                                                                Общестроительно-социальные объекты
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <label className="col-lg-6 fw-bold">
                                                            Рейтинг доверия / Балл
                                                        </label>
                                                        <div className="col-lg-6">
                          <span className="fw-bolder fs-6 text-dark">
                            {supplier?.ratingGeneralConstruction
                                ? ratingText(supplier.ratingGeneralConstruction) +
                                " (" +
                                ratingValue(
                                    supplier.ratingGeneralConstruction
                                ) +
                                ")"
                                : "Не имеется"}
                          </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="card-header cursor-pointer ps-0">
                                                        <div className="card-title m-0">
                                                            <h5 className="fw-bolder m-0">
                                                                Мелиорация и Орошение
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <label className="col-lg-6 fw-bold">
                                                            Рейтинг доверия / Балл
                                                        </label>
                                                        <div className="col-lg-6">
                          <span className="fw-bolder fs-6 text-dark">
                            {supplier?.ratingReclamationAndIrrigation
                                ? ratingText(
                                    supplier.ratingReclamationAndIrrigation
                                ) +
                                " / " +
                                ratingValue(
                                    supplier.ratingReclamationAndIrrigation
                                )
                                : "Не имеется"}
                          </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="card-header cursor-pointer ps-0">
                                                        <div className="card-title m-0">
                                                            <h5 className="fw-bolder m-0">
                                                                Автомобильные дороги, мосты
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <label className="col-lg-6 fw-bold">
                                                            Рейтинг доверия / Балл
                                                        </label>
                                                        <div className="col-lg-6">
                          <span className="fw-bolder fs-6 text-dark">
                            {supplier?.ratingHighwaysAndBridges
                                ? ratingText(supplier.ratingHighwaysAndBridges) +
                                " / " +
                                ratingValue(supplier.ratingHighwaysAndBridges)
                                : "Не имеется"}
                          </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="card-header cursor-pointer ps-0">
                                                        <div className="card-title m-0">
                                                            <h5 className="fw-bolder m-0">
                                                                Проектные организации
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <label className="col-lg-6 fw-bold">
                                                            Рейтинг доверия / Балл
                                                        </label>
                                                        <div className="col-lg-6">
                          <span className="fw-bolder fs-6 text-dark">
                            {supplier?.ratingDesignOrganizations
                                ? ratingText(supplier.ratingDesignOrganizations) +
                                " / " +
                                ratingValue(supplier.ratingDesignOrganizations)
                                : "Не имеется"}
                          </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header cursor-pointer p-0">
                                            <div className="card-title m-0">
                                                <h3 className="fw-bolder m-0">
                                                    Рейтинг устойчивости субъектов предпринимательства
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="card-body p-0">
                                            <div className="row mb-7">
                                                <label className="col-lg-6 fw-bold">
                                                    Категория рейтинга устойчивости
                                                </label>
                                                <div className="col-lg-6">
                      <span className="fw-bolder fs-6 text-dark text-uppercase">
                        {supplier?.ratingCategory}
                      </span>
                                                </div>
                                            </div>
                                            <div className="row mb-7">
                                                <label className="col-lg-6 fw-bold">Балл</label>
                                                <div className="col-lg-6">
                      <span className="fw-bolder fs-6 text-dark">
                        {supplier?.ratingBall}
                      </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            },
                            {
                              label: 'Лицензии',
                              key: 'license',
                                children: <>
                                    <div className="card">
                                        <div className="card-header cursor-pointer p-0">
                                            <div className="card-title m-0">
                                                <h3 className="fw-bolder m-0">
                                                    Зарегистрированные лицензии в реестре Министерства Юстиции
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="card-body p-0">
                                            <div className="row mb-7">
                                                <label className="col-lg-6 fw-bold">
                                                    Сведения о лицензиях
                                                </label>
                                                <div className="col-lg-6">
                      <span className="fw-bolder fs-6 text-dark">
                        {supplier?.licenseOfMinyust ? "Имеются" : "Отсутствуют"}
                      </span>
                                                </div>
                                            </div>
                                            <div className="row mb-7">
                                                <label className="col-lg-6 fw-bold">
                                                    {t("info")}
                                                </label>
                                                <div className="col-lg-6">
                                                    {supplier.licenseOfMinyust ? (
                                                        <OrganizationInfo
                                                            title="Минюст"
                                                            data={
                                                                supplier?.organizationMinjusts as CheckedSupplierMinjustDTO[]
                                                            }
                                                            type="minJust"
                                                        />
                                                    ) : (
                                                        <span className="fw-bolder fs-6 text-dark">
                               Отсутствуют
                             </span>
                                                    )}
                                                </div>
                                            </div>
                                            <div
                                                className={[
                                                    "row",
                                                    minjustFiles?.length > 0 ? " " : "mb-7",
                                                ].join(" ")}
                                            >
                                                <label className="col-lg-6 fw-bold">{t("file")}</label>
                                                {userMe?.organization?.tariffType !== "PLATINUM" ? (
                                                    <div className="col-lg-6">
                                                        <div className="badge badge-light-danger fs-6">
                                                            В ТАРИФЕ PLATINUM
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="col-12 mt-2 row row-cols-1 row-cols-sm-4 rol-cols-md-2 row-cols-lg-4 mx-0">
                                                        {minjustFiles?.map((file, i) => {
                                                            return (
                                                                <a
                                                                    onClick={() =>
                                                                        downloadFile(file?.url, file?.name)
                                                                    }
                                                                    className="col py-3 ps-0 mb-3"
                                                                    key={i}
                                                                    href="#/"
                                                                >
                                                                    <div
                                                                        className="btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start py-6">
                                                                        <div className="text-truncate">
                                                                            {file?.name}
                                                                        </div>
                                                                        <button
                                                                            className="btn btn-icon btn-bg-light btn-active-color-primary w-20px h-20px ms-2">
                                                                            <SVG
                                                                                icon={<DownloadIcon/>}
                                                                                className="svg-icon-2"
                                                                            />
                                                                        </button>
                                                                    </div>
                                                                </a>
                                                            );
                                                        })}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header cursor-pointer p-0">
                                            <div className="card-title m-0">
                                                <h3 className="fw-bolder m-0">
                                                    Зарегистрированные лицензии в Министерстве Транспорта
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="card-body p-0">
                                            <div className="row mb-7">
                                                <label className="col-lg-6 fw-bold">
                                                    Сведения о лицензиях
                                                </label>
                                                <div className="col-lg-6">
                      <span className="fw-bolder fs-6 text-dark">
                        {supplier?.licenseOfmintrans
                            ? "Имеются"
                            : "Отсутствуют"}
                      </span>
                                                </div>
                                            </div>
                                            <div
                                                className={[
                                                    "row d-flex flex-column",
                                                    mintransFiles?.length > 0 ? " " : "mb-7",
                                                ].join(" ")}
                                            >
                                                <label className="col-lg-6 fw-bold">{t("file")}</label>
                                                {userMe?.organization?.tariffType !== "PLATINUM" ? (
                                                    <div className="col-lg-6">
                                                        <div className="badge badge-light-danger fs-6">
                                                            В ТАРИФЕ PLATINUM
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="col-12 mt-2 row row-cols-1 row-cols-sm-4 rol-cols-md-2 row-cols-lg-4 mx-0">
                                                        {mintransFiles?.map((file, i) => {
                                                            return (
                                                                <a
                                                                    onClick={() =>
                                                                        downloadFile(file?.url, file?.name)
                                                                    }
                                                                    className="col py-3 ps-0 mb-3"
                                                                    key={i}
                                                                    href="#/"
                                                                >
                                                                    <div
                                                                        className="btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start py-6">
                                                                        <div className="text-truncate">
                                                                            {file?.name}
                                                                        </div>
                                                                        <button
                                                                            className="btn btn-icon btn-bg-light btn-active-color-primary w-20px h-20px ms-2">
                                                                            <SVG
                                                                                icon={<DownloadIcon/>}
                                                                                className="svg-icon-2"
                                                                            />
                                                                        </button>
                                                                    </div>
                                                                </a>
                                                            );
                                                        })}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            },
                            {
                                label: 'Степень риска контрагента',
                                key: 'level',
                                children: <>
                                    <div className="card-body mt-6">
                                        <div className="row mb-7">
                                            <div className="col-lg-6">
                                                <div className="scoring h-40px position-relative">
                                                    <div className="w-100 h-100 d-flex position-relative rounded-2 shadow-lg overflow-hidden">
                                                        <div
                                                            className="w-100 h-100 z-index-2
                                    position-absolute top-0 start-0 border-2 border-white
                                    border-solid
                                    bg-transparent rounded-2"
                                                            style={{
                                                                background:
                                                                    "linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%)",
                                                            }}
                                                        ></div>
                                                    </div>
                                                    <div
                                                        className="scoring-line z-index-3
                                   position-absolute
                                     w-4px shadow-sm rounded"
                                                        style={{
                                                            left: `calc(${
                                                                50 + 2.5 * Number(supplier?.calculation)
                                                            }% - 2px)`,
                                                            backgroundColor: "#7a8117",
                                                        }}
                                                    >
                          <span
                              className="position-absolute
                            bg-white shadow-sm
                            d-flex
                            align-items-center
                            justify-content-center
                            bottom-100
                             text-dark rounded-circle h-35px w-35px"
                              style={{
                                  left: "-16px",
                              }}
                          >
                            {supplier?.calculation !== null
                                ? supplier?.calculation?.includes("-")
                                    ? supplier?.calculation
                                    : "+" + supplier?.calculation
                                : "-/+"}
                          </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="row mb-7">
                                                <label className="col-lg-6 fw-bold">
                                                    Долг по налогам
                                                </label>
                                                <div className="col-lg-6">
                        <span className="fw-bolder fs-6 text-dark">
                          {formatMoney(supplier?.taxDebt) || "Нет"}{" "}
                            {hasNumbers(supplier?.taxDebt) ? "сум" : ""}
                        </span>
                                                </div>
                                            </div>
                                            <div className="row mb-7">
                                                <label className="col-lg-6 fw-bold">
                                                    Наличие судебных дел
                                                </label>
                                                <div className="col-lg-6">
                        <span className="fw-bolder fs-6 text-dark">
                          {supplier.isEconomicSud ? "Да" : "Нет"}
                        </span>
                                                </div>
                                            </div>
                                            <div className="row mb-7">
                                                <label className="col-lg-6 fw-bold">
                                                    Недобросовестный исполнитель
                                                </label>
                                                <div className="col-lg-6">
                        <span className="fw-bolder fs-6 text-dark">
                          {supplier?.unscrupulousPerformerNumber ? "Дв" : "Нет"}
                        </span>
                                                </div>
                                            </div>
                                            <div className="row mb-7">
                                                <label className="col-lg-6 fw-bold">
                                                    Долг в БПИ (юр.лица)
                                                </label>
                                                <div className="col-lg-6">
                        <span className="fw-bolder fs-6 text-dark">
                          {supplier?.organizationMibDebt === null
                              ? ""
                              : supplier?.organizationMibDebt === "0"
                                  ? t("no")
                                  : formatMoney(supplier?.organizationMibDebt) +
                                  " сум"}
                        </span>
                                                </div>
                                            </div>
                                            <div className="row mb-7">
                                                <label className="col-lg-6 fw-bold">
                                                    В списке сомнительных НК
                                                </label>
                                                <div className="col-lg-6">
                        <span className="fw-bolder fs-6 text-dark">
                          {supplier.obnalStatus ? "Да" : "Нет"}
                        </span>
                                                </div>
                                            </div>
                                            <div className="row mb-7">
                                                <label className="col-lg-6 fw-bold">
                                                    Находится в процессе ликвидации
                                                </label>
                                                <div className="col-lg-6">
                        <span className="fw-bolder fs-6 text-dark">
                          {supplier?.liquidation ? "Да" : "Нет"}
                        </span>
                                                </div>
                                            </div>
                                            <div className="row mb-7">
                                                <label className="col-lg-6 fw-bold">
                                                    Зарегистрирован менее 3 месяцев
                                                </label>
                                                <div className="col-lg-6">
                        <span className="fw-bolder fs-6 text-dark">
                          {supplier.isOrganization3MonthWork ? "Да" : "Нет"}
                        </span>
                                                </div>
                                            </div>
                                            <div className="row mb-7">
                                                <label className="col-lg-6 fw-bold">
                                                    Статус налогоплательщика
                                                </label>
                                                <div className="col-lg-6 fv-row">
                        <span className="fw-bolder fs-6">
                          {capitalizeFirstLetter(supplier?.activityStatus)}
                        </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            },
                            {
                                label: t("additionalInfo"),
                                key: "additionalInfo",
                                children: <>
                                    <div className="card p-0">
                                        <div className="card-header cursor-pointer p-0">
                                            <div className="card-title m-0">
                                                <h3 className="fw-bolder m-0">{t("supplierObject")}</h3>
                                            </div>
                                        </div>
                                        <div className="card-body p-0">
                                            {supplier.organizationCadasters
                                                ? supplier.organizationCadasters.map((el, i) => {
                                                    return el.number!.length ? (
                                                        <React.Fragment key={i}>
                                                            <div className="row mb-7">
                                                                <label className="col-lg-6 fw-bold">
                                                                    {t("cadastreNumber")}
                                                                </label>
                                                                <div className="col-lg-6">
                                <span className="fw-bolder fs-6 text-dark">
                                  {el?.number}
                                </span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-7">
                                                                <label className="col-lg-6 fw-bold">
                                                                    {t("area")}
                                                                </label>
                                                                <div className="col-lg-6">
                                <span className="fw-bolder fs-6 text-dark">
                                  {el?.area}
                                </span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-7">
                                                                <label className="col-lg-6 fw-bold">
                                                                    {t("ban")}
                                                                </label>
                                                                <div className="col-lg-6">
                                <span className="fw-bolder fs-6 text-dark">
                                  {el.ban ? "Да" : "Нет"}
                                </span>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-7">
                                                                <label className="col-lg-6 fw-bold">
                                                                    {t("info")}
                                                                </label>
                                                                <div className="col-lg-6">
                                <span className="fw-bolder fs-6 text-dark">
                                  {el?.info}
                                </span>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    ) : (
                                                        <div className="row mb-7" key={i}>
                                                            <label className="col-lg-6 fw-bold">Сведения</label>
                                                            <div className="col-lg-6">
                              <span className="fw-bolder fs-6 text-dark">
                                Отсутствуют
                              </span>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                                : ""}
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header cursor-pointer p-0">
                                            <div className="card-title m-0">
                                                <h3 className="fw-bolder m-0">{t("lastCheckGNK")}</h3>
                                            </div>
                                        </div>
                                        <div className="card-body p-0">
                                            <div className="row mb-7">
                                                <label className="col-lg-6 fw-bold">Тип проверки</label>
                                                <div className="col-lg-6">
                      <span className="fw-bolder fs-6 text-dark">
                        {supplier?.lastCheckGNK?.toString()?.split(',').join(', ')}
                      </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header cursor-pointer p-0">
                                            <div className="card-title m-0">
                                                <h3 className="fw-bolder m-0">
                                                    Недобросовестные исполнители в едином реестре АО
                                                    "Узбекская республиканская товарно-сырьевая биржа"
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="card-body p-0">
                                            {supplier?.unscrupulousPerformerNumber !== null ? (
                                                <>
                                                    <div className="row mb-7">
                                                        <label className="col-lg-6 fw-bold">
                                                            Номер решения суда
                                                        </label>
                                                        <div className="col-lg-6 fv-row">
                          <span className="fw-bolder fs-6">
                            {supplier?.unscrupulousPerformerExitDate}
                          </span>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-7">
                                                        <label className="col-lg-6 fw-bold">
                                                            Дата внесения
                                                        </label>
                                                        <div className="col-lg-6 fv-row">
                          <span className="fw-bolder fs-6">
                            <span className="fw-bolder fs-6">
                              {supplier?.unscrupulousPerformerEntryDate}
                            </span>
                          </span>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-7">
                                                        <label className="col-lg-6 fw-bold">
                                                            Дата удаления
                                                        </label>
                                                        <div className="col-lg-6 fv-row">
                          <span className="fw-bolder fs-6">
                            <span className="fw-bolder fs-6">
                              {supplier?.unscrupulousPerformerExitDate}
                            </span>
                          </span>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <div className="row mb-7">
                                                    <label className="col-lg-6 fw-bold">Сведения</label>
                                                    <div className="col-lg-6 fv-row">
                        <span className="fw-bolder fs-6">
                          <span className="fw-bolder fs-6">Отсутствуют</span>
                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </>
                            }
                        ]}
                    />
                </div>}
            />
        </>
    );
};

export default SupplierInfo;
import { Row } from "antd";
import { Col } from "antd/lib/grid";
import { ChangeEvent, FormEvent, useState } from "react";
import { toast } from "react-toastify";
import { IAuth } from "../../models";
import { LoginWrapper } from "./Style";
import loginImg from "../../assets/images/login.jpg";
import useAppDispatch from "./../../hooks/useAppDispatch";
import { login } from "../../redux/services/authService";
import { useAppSelector } from "../../hooks";
import { useTranslation } from "react-i18next";
import {getUserMe} from "../../redux/services/user";
import i18n from "i18next";
import {setStorage} from "../../helpers/localStorage";
import {SVG} from "../core/SVG";
import {EyeIcon} from "../Icons";

const LoginPage = () => {
  const { t } = useTranslation();

  const [form, setForm] = useState<IAuth>({
    password: "",
    phoneNumber: "",
  });

  const [showPass, setShowPass] = useState<boolean>(false)

  const { isLoading } = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };
  const isFormValid = () => {
    return form.password.trim().length && form.phoneNumber.trim().length;
  };

  const handleTogglePass = () => {
    setShowPass((showPass) => !showPass)
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isFormValid()) {
      let res: any = await dispatch(login(form));
      if (res?.success) {
        toast(t("successfully"), {
          type: "success",
        });
        let res2: any = await dispatch(getUserMe())
        await i18n.changeLanguage(res2?.payload?.lang || "RU");
        await setStorage('_lang', res2?.payload?.lang || "RU")
      }
    } else {
      toast("Please fill all fields!", {
        type: "warning",
      });
    }
  };

  return (
    <LoginWrapper>
      <Row align="middle" justify="center">
        <Col xs={24} sm={24} md={24} className="left">
          <img src={loginImg} alt="login" />
        </Col>
        <Col xs={24} sm={12} md={10} className="right z-index-2">
          <form className="form w-90" onSubmit={handleSubmit}>
            <div className="text-center mb-6">
              <h1 className="text-white mb-3 text-uppercase">
                Smart Info Data
              </h1>
            </div>
            <div className="fv-row mb-6">
              <div className="d-flex flex-stack mb-2">
                <label className="form-label fw-bolder text-white fs-6 mb-0">
                  {t('login')}
                </label>
              </div>
              <input
                className="form-control form-control-lg form-control-solid"
                type="text"
                name="phoneNumber"
                autoComplete="off"
                onChange={changeHandler}
              />
            </div>
            <div className="fv-row mb-6 position-relative">
              <div className="d-flex flex-stack mb-2">
                <label className="form-label fw-bolder text-white fs-6 mb-0">
                  {t('password')}
                </label>
              </div>
              <input
                className="form-control form-control-lg form-control-solid"
                type={showPass ? "text" : "password"}
                name="password"
                autoComplete="off"
                onChange={changeHandler}
              />
              <button type="button" onClick={handleTogglePass} className="btn position-absolute end-0 bottom-0">
                <SVG icon={<EyeIcon/> } />
              </button>
            </div>
            <div className="text-center">
              <button
                type="submit"
                id="kt_sign_in_submit"
                className="btn btn-lg btn-primary w-100 mb-5"
              >
                <span className="indicator-label">{t('signIn')}</span>
                <span
                  className={`indicator-${isLoading ? "label" : "progress"}`}
                >
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              </button>
            </div>
          </form>
        </Col>
      </Row>
    </LoginWrapper>
  );
};

export default LoginPage;
